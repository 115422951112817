sh-page[theme="dark"] img[src*="Light"] {
  display: none !important;
}
sh-page[theme="light"] img[src*="Dark"] {
  display: none !important;
}
.md-wrapper markdown {
	padding: 32px 32px !important;
}

markdown img {
  width: 100%;
}
pre {
  background: rgba(var(--ui-1), var(--opacity-7)) !important;
  max-height: 320px !important;
  padding: 16px !important;
  margin: 0 0 24px 0 !important;
}
pre code {
  font-size: 14px !important;
  line-height: 20px !important;
  text-shadow: none !important;
  padding: 0;
  background: transparent;
  color: var(--text-secondary) !important;
}
pre code .token.punctuation,
pre code .token.operator {
  color: var(--text-secondary) !important;
}
pre code .token.comment {
  color: var(--support-3) !important;
}
pre code .token.attr-name,
pre code .token.function {
  color: var(--support-4) !important;
}
pre code .token.tag,
pre code .token.keyword {
  color: var(--support-5) !important;
}
pre code .token.attr-value,
pre code .token.string {
  color: var(--support-6) !important;
}
code {
  background: rgba(var(--ui-1), var(--opacity-7));
  color: var(--support-4);
  padding: 2px 4px;
  border-radius: 2px;
}
markdown > sh-text[size="super-header"], markdown > ul sh-text[size="super-header"] {
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  top: 24px;
  width: calc(100% - 64px);
  max-width: 896px;
  margin: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(var(--ui-1), var(--opacity-5));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
markdown > sh-text[size="header-2"].h2, markdown > ul sh-text[size="header-2"].h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
  line-height: 32px;
  padding-top: 24px;
}
markdown > sh-text[size="header-2"].h3, markdown > ul sh-text[size="header-2"].h3 {
  font-size: 16px;
  margin: 0 0 8px 0;
  padding-top: 24px;
}
markdown > sh-text[size="header-2"].h4, markdown > ul sh-text[size="header-2"].h4 {
  font-size: 14px !important;
  margin: 0 0 8px 0 !important;
  padding-top: 24px !important;
}
markdown > sh-text[size="header-2"].h5, markdown > ul sh-text[size="header-2"].h5 {
  font-size: 16px;
  line-height: 56px;
  position: absolute;
  top: 64px;
  width: calc(100% - 64px);
  max-width: 896px;
  margin: auto;
}
markdown > sh-text[size="body-1"], markdown > ul sh-text[size="body-1"] {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 24px 0;
}
markdown .link {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  margin: 0 !important;
  width: auto;
}
markdown li {
  font: var(--body-1);
  color: var(--text-primary);
  font-size: 16px;
  line-height: 32px;
}
markdown li > sh-text {
  margin: 0 !important;
}
markdown > sh-divider {
  padding: 4px 0;
}
markdown table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 24px;
}
markdown table th, markdown table td {
  font-size: 0.875rem;
  line-height: 24px !important;
  padding: 16px 12px 16px 0px;
  border-bottom: 1px solid rgba(var(--ui-1), var(--opacity-7));
  color: var(--text-primary);
}
markdown table th {
  font: var(--title-1);
  border-color: rgba(var(--ui-1), var(--opacity-4));
}
.demo + pre {
  margin-top: -32px !important;
}
.demo:not(iframe) {
  background-color: var(--base-2);
  overflow: visible;
  margin: 24px 0 24px 0;
  position: relative;
  border-radius: 2px;
  border: 1px solid rgba(var(--ui-1), var(--opacity-6));
  font-size: 14px;
}
.demo:not(iframe) > div {
  padding: 48px;
  display: block;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  height: fit-content;
  width: 320px;
  max-width: 100%;
  perspective: 1px;
}
.demo.template:not(iframe) > div {
  zoom: 55%;
  width: 100%;
}
.demo.phone:not(iframe) > div, .demo.tablet:not(iframe) > div {
  margin: 40px auto;
  border: 2px solid var(--highlight-20);
  overflow: hidden;
  border-radius: 16px;
  padding: 0px;
  min-width: 0;
}
.demo.custom-phone:not(iframe) > div, .demo.custom-tablet:not(iframe) > div {
  margin: 5px auto;
  border: 2px solid var(--highlight-20);
  overflow: hidden;
  border-radius: 16px;
  padding: 0px;
  min-width: 0;
}
.demo.phone:not(iframe) > div {
  height: 720px;
  width: 375px;
}
.demo.custom-phone:not(iframe) > div {
  height: 600px;
  width: 350px;
  transform: scale(0.85);
  -ms-transform: scale(0.85);
}
.demo.tablet:not(iframe) > div {
  height: 1024px;
  width: 768px;
  zoom: 80%;
}
.demo-cut:not(iframe) > div {
  height: 240px !important;
  width: 375px !important;
  border-radius: 16px 16px 0 0 !important;
  border-top: 2px solid var(--highlight-20);
  border-left: 2px solid var(--highlight-20);
  border-right: 2px solid var(--highlight-20);
  border-bottom: none !important;
  overflow: hidden !important;
  zoom: 100%;
}
.demo.stretch:not(iframe) > div {
  padding: 0;
  width: 100%;
}
.demo.row:not(iframe) > div {
  display: flex;
  flex-direction: row;
}
.demo.has-margin:not(iframe) > div > * {
  margin: 8px auto;
}
.demo.row.has-margin:not(iframe) > div > * {
  margin: auto 8px;
}
.demo.resizable:not(iframe) > div {
  display: block;
  resize: both;
  padding: 16px;
  overflow: auto;
  box-sizing: border-box;
  min-width: calc(375px + 32px);
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  margin: 16px auto;
  border-radius: 2px;
  border: 2px solid var(--support-1);
}
.demo.resizable.horizontal:not(iframe) > div {
  resize: horizontal;
}
.demo.resizable.vertical:not(iframe) > div {
  resize: vertical;
}
.demo:not(iframe) hr {
  margin: 8px 0 !important;
  height: 0px !important;
}
/* styles for iframe */
iframe {
  background-color: var(--base-2);
  overflow: visible;
  margin: 24px 0 32px 0;
  position: relative;
  border-radius: 2px;
  border: 1px solid rgba(var(--ui-1), var(--opacity-6));
}
iframe.demo {
  /* padding: 48px; */
  display: block;
  flex-direction: column;
  margin: 24px auto;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  min-width: 375px;
  max-width: 100%;
  perspective: 1px;
}
iframe.resizable {
  display: block;
  resize: both;
  overflow: auto;
  width: 1048px;
  box-sizing: border-box;
  max-width: 1048px;
  min-width: calc(375px + 18px);
  transform: translateX(-76px);
  border-radius: 2px;
  border: 1px solid var(--support-1);
  margin: 24px 0;
}
iframe.horizontal {
  resize: horizontal;
}
iframe.resizable.vertical {
  resize: vertical;
}
iframe.stretch {
  padding: 0;
  width: 100%;
}
/* styles for demo wrapper (inside iframe) */
.iframe {
  height: 100%;
}
.iframe.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iframe.column > * + * {
  margin-top: 16px;
}
.iframe.row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframe.row > * + * {
  margin-left: 16px;
}
.iframe.template {
  zoom: 55%;
  width: 100%;
}
.iframe.phone {
  position: absolute;
  width: 375px;
  overflow: hidden;
  padding: 0px;
}
.iframe.phone.cut {
  height: 240px;
}
.iframe.has-margin > * {
  margin: 8px auto;
}
/* home screen quick links */
.links-grid {
  margin: 0 2px;
}
.links-grid sh-home-card {
  height: 240px;
}
.links-grid sh-grid a {
  text-decoration: none;
}
.links-grid sh-grid a .quick-links sh-text[href] {
  display: inline-block;
  width: auto;
}
.color-sample {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(var(--ui-1), var(--opacity-6));
}
*[hidden] {
  display: none !important;
}